/**
 * @generated SignedSource<<8c1a13f875dcda297666cb2c492b8bce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AvailableLanguagesEnum = "ar" | "bg" | "bs" | "cmn" | "cnr" | "da" | "de" | "el" | "en" | "es" | "et" | "fil" | "fr" | "he" | "hi" | "hr" | "hu" | "id" | "it" | "ja" | "lt" | "lv" | "ms" | "nl" | "no" | "pl" | "pt" | "ro" | "ru" | "sk" | "sq" | "sr" | "sv" | "th" | "tr" | "uk" | "ur" | "yue";
export type SessionStatusEnum = "ACTIVE" | "AWAITING_PAYMENT" | "CANCELLED" | "CANCELLED_LATE" | "COMPLETED" | "EMPLOYEE_NO_SHOW" | "NO_SHOW" | "PAYMENT_EXPIRED" | "PROFESSIONAL_NO_SHOW" | "SCHEDULED";
export type SessionTranscriptStatusEnum = "FETCHING" | "FETCHING_ERROR" | "PENDING" | "PROCESSED" | "PROCESSED_WITH_ERRORS" | "PROCESSING";
export type SessionTypeEnum = "DROP_IN" | "MANAGER_ONE_TO_ONE" | "TEST" | "THERAPY";
import { FragmentRefs } from "relay-runtime";
export type useProfessionalSessionCard_fragment$data = {
  readonly __typename: "GroupSession";
  readonly cancelledById: string | null | undefined;
  readonly cancelledByName: string | null | undefined;
  readonly endDate: string;
  readonly id: string;
  readonly language: AvailableLanguagesEnum;
  readonly participants: ReadonlyArray<{
    readonly id: string;
  }>;
  readonly startDate: string;
  readonly status: SessionStatusEnum;
  readonly workshop: {
    readonly illustrationCard: string;
    readonly title: string;
  } | null | undefined;
  readonly " $fragmentType": "useProfessionalSessionCard_fragment";
} | {
  readonly __typename: "Session";
  readonly appointment: {
    readonly rescheduledPreviousStartDate: string | null | undefined;
  };
  readonly automatedSummary: {
    readonly summary: {
      readonly __typename: "ProcessedProfessionalSessionSummary";
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    };
  } | null | undefined;
  readonly cancelledById: string | null | undefined;
  readonly cancelledByName: string | null | undefined;
  readonly checkoutUrl: string | null | undefined;
  readonly employee: {
    readonly name: string;
    readonly settings: {
      readonly allowDataProcessing: boolean | null | undefined;
    } | null | undefined;
    readonly surname: string;
  } | null | undefined;
  readonly endDate: string;
  readonly id: string;
  readonly language: AvailableLanguagesEnum;
  readonly settings: {
    readonly allowDataProcessing: boolean | null | undefined;
  } | null | undefined;
  readonly startDate: string;
  readonly status: SessionStatusEnum;
  readonly transcriptionStatus: SessionTranscriptStatusEnum | null | undefined;
  readonly type: SessionTypeEnum;
  readonly " $fragmentType": "useProfessionalSessionCard_fragment";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "useProfessionalSessionCard_fragment";
};
export type useProfessionalSessionCard_fragment$key = {
  readonly " $data"?: useProfessionalSessionCard_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useProfessionalSessionCard_fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "allowDataProcessing",
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cancelledByName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cancelledById",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "language",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useProfessionalSessionCard_fragment",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "checkoutUrl",
          "storageKey": null
        },
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "employee",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "surname",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "UserSettings",
              "kind": "LinkedField",
              "name": "settings",
              "plural": false,
              "selections": (v5/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SessionAppointment",
          "kind": "LinkedField",
          "name": "appointment",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "rescheduledPreviousStartDate",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v6/*: any*/),
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "SessionSettings",
          "kind": "LinkedField",
          "name": "settings",
          "plural": false,
          "selections": (v5/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AutomatedSessionSummary",
          "kind": "LinkedField",
          "name": "automatedSummary",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "summary",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "type": "ProcessedProfessionalSessionSummary",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v8/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "transcriptionStatus",
          "storageKey": null
        }
      ],
      "type": "Session",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Workshop",
          "kind": "LinkedField",
          "name": "workshop",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "illustrationCard",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "participants",
          "plural": true,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        (v6/*: any*/),
        (v7/*: any*/),
        (v8/*: any*/)
      ],
      "type": "GroupSession",
      "abstractKey": null
    }
  ],
  "type": "SessionResponse",
  "abstractKey": "__isSessionResponse"
};
})();

(node as any).hash = "3e9ddcdaf516c45622add202b80808ca";

export default node;
