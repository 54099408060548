import {
  CalendarIcon,
  UsersIcon,
  RoseIcon,
  Tag,
  Text,
} from "@olivahealth/oli-ui";
import tokens from "@olivahealth/oli-ui/theme/tokens";

import useTranslation from "../../../hooks/useTranslation";
import {
  formatShortDate,
  formatShortDateTime,
  formatTime,
} from "../../../utils/date/format";

import * as s from "./styles";

interface SessionDetailsProps {
  isGroupSession: boolean;
  isCancelled: boolean;
  startDate: string;
  endDate: string;
  rescheduledPreviousStartDate: string | null;
  workshopTitle: string;
  participantsCount: number | null;
  isOngoing: boolean;
}

const ICON_SIZE = 24;
const ICON_COLOR = tokens.colors["neutral"][500];

export const GroupSessionDetails = ({
  workshopTitle,
  isCancelled,
  startDate,
  endDate,
  participantsCount,
}: Pick<
  SessionDetailsProps,
  | "workshopTitle"
  | "isCancelled"
  | "startDate"
  | "endDate"
  | "participantsCount"
>) => {
  const { t } = useTranslation("professionalDashboard");

  return (
    <div className={s.groupSessionDetailsWrapper}>
      <div className={s.groupSessionRow}>
        <div className={s.desktopIconWrapper}>
          <RoseIcon size={ICON_SIZE} color={ICON_COLOR} />
        </div>
        <Text weight="bold">{workshopTitle}</Text>
      </div>
      <div className={s.groupSessionRow}>
        <div className={s.desktopIconWrapper}>
          <CalendarIcon
            size={ICON_SIZE}
            color={ICON_COLOR}
            variant={isCancelled ? "cross" : "default"}
          />
        </div>
        <div className="flex flex-col">
          <Text weight="bold">{formatShortDate(startDate)}</Text>
          <Text>{`${formatTime(startDate)} - ${formatTime(endDate)}`}</Text>
        </div>
      </div>
      <div className="flex">
        <div className="mr-3">
          <UsersIcon size={ICON_SIZE} color={ICON_COLOR} />
        </div>
        <Text>{t("sessionCard.participantsCount", { participantsCount })}</Text>
      </div>
    </div>
  );
};

export const SessionDetails = ({
  isCancelled,
  startDate,
  endDate,
  rescheduledPreviousStartDate,
  isOngoing,
}: Pick<
  SessionDetailsProps,
  | "isCancelled"
  | "startDate"
  | "endDate"
  | "rescheduledPreviousStartDate"
  | "isOngoing"
>) => {
  const { t } = useTranslation("professionalDashboard");

  return (
    <div className={s.sessionDetailsWrapper}>
      <div className={s.desktopIconWrapper}>
        <CalendarIcon
          size={ICON_SIZE}
          color={ICON_COLOR}
          variant={isCancelled ? "cross" : "default"}
        />
      </div>
      <div>
        <Text weight="bold">{formatShortDate(startDate)}</Text>
        <Text>{`${formatTime(startDate)} - ${formatTime(endDate)}`}</Text>
        {!!rescheduledPreviousStartDate && !isCancelled && (
          <div className="flex items-center">
            <Text color="light">
              <span className="mr-2 line-through">
                {formatShortDateTime(rescheduledPreviousStartDate)}
              </span>
            </Text>
            {!isOngoing && (
              <div className={s.rescheduledTagWrapper}>
                <Tag>{t("sessionCard.rescheduledTag")}</Tag>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
