import { useEffect, useRef, useState } from "react";

import { SURVEY_EMPLOYEE_NPS_QUESTION_IDS } from "@olivahealth/constants";
import { NativeSurveyVariables } from "../../../../components/common/NativeSurvey";
import {
  NativeSurveyAnswers,
  NativeSurveyFormInitialState,
  OnAnswersChanged,
  SurveyFlowToEventsMapping,
} from "../../../../components/common/NativeSurvey/useNativeSurvey";
import { OlivaHook } from "../../../../hooks/OlivaHook";
import { useAmplitude } from "../../../../services/contexts/AmplitudeContext";
import {
  defaultSharedStoreContext,
  ISharedStoreContext,
  useSharedStoreContext,
} from "../../../../services/contexts/SharedStoreContext";
import { useUserData } from "../../../../services/contexts/UserDataContext";
import { setLocalStorageDismissedAt } from "../../../../utils/survey/nps-helper";

type EmployeeNPSSurveyFormStatus =
  | "OPENED"
  | "DISMISSED"
  | "COMPLETED"
  | "CLOSED";

interface UseEmployeeNPSSurveyData {
  formInitialState?: NativeSurveyFormInitialState;
  formStatus: EmployeeNPSSurveyFormStatus;
  pendingSubmissionId: string | null;
  surveyFlowToEventsMapping: SurveyFlowToEventsMapping;
  variables: NativeSurveyVariables;
  willLeaveReview: boolean;
}

interface UseEmployeeNPSSurveyEffects {
  onAnswersChanged?: OnAnswersChanged;
  onDismissButtonClick: () => void;
  onSubmitSuccess: () => void;
  closeSurvey: () => void;
}

type UseEmployeeNPSSurvey = OlivaHook<
  UseEmployeeNPSSurveyData,
  UseEmployeeNPSSurveyEffects
>;

export default function useEmployeeNPSSurvey(): UseEmployeeNPSSurvey {
  const { trackEvent } = useAmplitude();
  const {
    surveyEmployeeNPS: { pendingSubmissionId, formInitialState },
    setSharedStore,
  } = useSharedStoreContext();
  const [willLeaveReview, setWillLeaveReview] = useState<boolean>(false);
  let initialFormStatus = "CLOSED";
  if (pendingSubmissionId) {
    initialFormStatus = "OPENED";
  }

  if (willLeaveReview) {
    initialFormStatus = "COMPLETED";
  }

  const [formStatus, setFormStatus] = useState<EmployeeNPSSurveyFormStatus>(
    initialFormStatus as EmployeeNPSSurveyFormStatus,
  );
  const [variables, setVariables] = useState<NativeSurveyVariables>({});
  const latestSurveyContextState =
    useRef<ISharedStoreContext["surveyEmployeeNPS"]>();
  const { refetchData: refetchUserData } = useUserData();

  const surveyFlowToEventsMapping: SurveyFlowToEventsMapping = {
    onSurveyOpened: [
      {
        name: "NPS survey viewed",
        eventProperties: {
          surveyType: "EMPLOYEE_NPS",
        },
      },
    ],
    onQuestionAnswered: {
      [SURVEY_EMPLOYEE_NPS_QUESTION_IDS.RATING]: [
        {
          name: "NPS survey started",
          eventProperties: {
            surveyType: "EMPLOYEE_NPS",
            submissionId: pendingSubmissionId,
          },
        },
      ],
    },
    onSurveyCompleted: [
      {
        name: "NPS survey finished",
        eventProperties: {
          surveyType: "EMPLOYEE_NPS",
        },
      },
    ],
  };

  const emptySurveyContextState = () => {
    latestSurveyContextState.current =
      defaultSharedStoreContext.surveyEmployeeNPS;

    setSharedStore({
      surveyEmployeeNPS: latestSurveyContextState.current,
    });
  };

  const onDismissButtonClick = () => {
    setFormStatus("DISMISSED");
    setLocalStorageDismissedAt("employee");
    emptySurveyContextState();
    trackEvent("NPS survey dismissed", {
      surveyType: "EMPLOYEE_NPS",
      submissionId: pendingSubmissionId,
    });
  };

  const onAnswersChanged = (
    answers: NativeSurveyAnswers,
    currentQuestionIndex: number,
  ) => {
    const chosenReview = answers.get(
      SURVEY_EMPLOYEE_NPS_QUESTION_IDS.LEAVE_A_REVIEW,
    );
    if (chosenReview) {
      setWillLeaveReview(chosenReview.chosenOption.value === "YES");
    }

    latestSurveyContextState.current = {
      pendingSubmissionId,
      formInitialState: {
        initialAnswers: answers,
        currentQuestionIndex,
      },
    };

    if (currentQuestionIndex === 1) {
      const newScore = answers.get(SURVEY_EMPLOYEE_NPS_QUESTION_IDS.RATING)
        ?.chosenOption.value;

      if (newScore !== variables.score) {
        setVariables({
          score: newScore,
        });
      }
    }
  };

  const onSubmitSuccess = () => {
    setFormStatus("COMPLETED");
    emptySurveyContextState();
    refetchUserData();
  };

  useEffect(() => {
    return () => {
      if (latestSurveyContextState.current?.pendingSubmissionId) {
        setSharedStore({
          surveyEmployeeNPS: latestSurveyContextState.current,
        });
      }
    };
  }, []);

  return {
    status: "success",
    data: {
      formInitialState,
      formStatus,
      pendingSubmissionId,
      surveyFlowToEventsMapping,
      variables,
      willLeaveReview,
    },
    effects: {
      onAnswersChanged,
      onDismissButtonClick,
      onSubmitSuccess,
      closeSurvey: () => setFormStatus("CLOSED"),
    },
  };
}
