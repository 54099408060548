import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";

import OrganisationPlanType from "@olivahealth/graphql-server/src/domain/value-objects/OrganisationPlanType";

import { OlivaHook } from "../../../../hooks/OlivaHook";
import useTranslation, { TFunction } from "../../../../hooks/useTranslation";
import { useUserData } from "../../../../services/contexts/UserDataContext";

export interface UseGetStartedBannerData {
  bannerTitle: string;
  isUserInTrialOrg: boolean;
}

export interface UseGetStartedBannerEffects {
  onGetStartedButtonClick: () => void;
  t: TFunction;
}

type UseGetStartedBanner = OlivaHook<
  UseGetStartedBannerData,
  UseGetStartedBannerEffects
>;

interface Props {
  hasWellbeingProfile: boolean;
}

export default function useGetStartedBanner({
  hasWellbeingProfile,
}: Props): UseGetStartedBanner {
  const { t } = useTranslation("employeeDashboard", {
    keyPrefix: "getStartedBanner",
  });
  const { push } = useRouter();
  const { data: user } = useUserData();

  const isUserInTrialOrg = useMemo(
    () => user?.organisation?.plan?.type === OrganisationPlanType.TRIAL,
    [user?.organisation?.plan?.type],
  );

  const bannerTitle = useMemo((): string => {
    if (user?.name) {
      if (hasWellbeingProfile) {
        return t("titleWithNameHasWellbeingProfile", {
          name: user?.name,
        });
      }

      return t("titleWithName", { name: user?.name });
    }

    return t("titleNoName");
  }, [hasWellbeingProfile, t, user?.name]);

  const onGetStartedButtonClick = useCallback(() => {
    push("/wellbeing");
  }, [push]);

  return {
    status: "success",
    data: {
      bannerTitle,
      isUserInTrialOrg,
    },
    effects: {
      onGetStartedButtonClick,
      t,
    },
  };
}
