import { useRouter } from "next/router";
import { ReactElement } from "react";
import { MessageBanner } from "@olivahealth/oli-ui";
import Image from "../../../components/common/Image";
import useTranslation from "../../../hooks/useTranslation";

export default function PendingSurveyEmployeeUnsatisfiedWithProfessionalBanner(): ReactElement {
  const { push } = useRouter();
  const { t } = useTranslation("common", {
    keyPrefix: "pendingSurveyEmployeeUnsatisfiedWithProfessionalBanner",
  });

  return (
    <MessageBanner
      dataTestId="pending-survey-employee-unsatisfied-with-professional-banner"
      gutter="md"
      isOpen
      icon={
        <Image
          alt={t("altTextIcon")}
          src="/img/img_heart_broken.png"
          height={90}
          width={90}
        />
      }
      primaryAction={{
        action: () => {
          push("/surveys/employee-unsatisfied-with-professional?o=banner");
        },
        title: t("buttonShareMoreDetailsText"),
      }}
      message={t("title")}
      description={t("description")}
      variant="info"
    />
  );
}
