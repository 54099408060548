import useTranslation from "../../../hooks/useTranslation";
import Image from "../../../components/common/Image";
import SessionCardTags from "./SessionCardTags";

interface Props {
  isCancelled: boolean;
  isPast: boolean;
  isCheckIn: boolean;
  isGroupSession: boolean;
  groupSessionIllustrationSrc: string | null;
  isAwaitingPayment: boolean;
  cancelledById?: string | null;
  cancelledByName?: string | null;
}

export default function SessionCardImage({
  isCancelled,
  isPast,
  isCheckIn,
  isGroupSession,
  groupSessionIllustrationSrc,
  isAwaitingPayment,
  cancelledById,
  cancelledByName,
}: Props) {
  const { t } = useTranslation("professionalDashboard", {
    keyPrefix: "imgAlt",
  });

  let imgSrc = "";
  let imgAlt = "";

  if (isCancelled) {
    if (isGroupSession) {
      imgSrc = "/img/img_prof_group_class_placeholder_red.png";
      imgAlt = t("groupClass.cancelled");
    } else if (isCheckIn) {
      imgSrc = "/img/img_calendar_square_red.png";
      imgAlt = t("checkIn.cancelled");
    } else {
      imgSrc = "/img/img_planet_square_red.png";
      imgAlt = t("session.cancelled");
    }
  } else if (isPast) {
    if (isGroupSession) {
      imgSrc = "/img/img_prof_group_class_placeholder_grey.png";
      imgAlt = t("groupClass.past");
    } else if (isCheckIn) {
      imgSrc = "/img/img_calendar_square_grey.png";
      imgAlt = t("checkIn.past");
    } else {
      imgSrc = "/img/img_planet_square_grey.png";
      imgAlt = t("session.past");
    }
  } else {
    if (isGroupSession) {
      imgSrc =
        groupSessionIllustrationSrc ||
        "/img/img_prof_group_class_placeholder.png";
      imgAlt = t("groupClass.upcoming");
    } else if (isCheckIn) {
      imgSrc = "/img/img_calendar_square.png";
      imgAlt = t("checkIn.upcoming");
    } else {
      imgSrc = "/img/img_planet_square.png";
      imgAlt = t("session.upcoming");
    }
  }

  return (
    <div className="relative">
      <Image
        src={imgSrc}
        alt={imgAlt}
        className="rounded-xl"
        width="140"
        height="140"
      />
      <div className="absolute bottom-0 w-full">
        <SessionCardTags
          isCancelled={isCancelled}
          isAwaitingPayment={isAwaitingPayment}
          cancelledById={cancelledById}
          cancelledByName={cancelledByName}
          mobileView
        />
      </div>
    </div>
  );
}
