import cx from "classnames";
import { ReactElement } from "react";

import { BackgroundHeader, Button, Text } from "@olivahealth/oli-ui";

import Image from "../../../../components/common/Image";
import VIPTrialSurvey from "../../../organisms/EmbededSurveys/VIPTrialSurvey";
import { getStartedBanner as s } from "../styles";
import EmployeeNPSSurvey from "../EmployeeNPSSurvey";
import useGetStartedBanner from "./useGetStartedBanner";

interface Props {
  hasWellbeingProfile: boolean;
}

export default function GetStartedBanner({
  hasWellbeingProfile,
}: Props): ReactElement {
  const {
    data: { bannerTitle, isUserInTrialOrg },
    effects: { onGetStartedButtonClick, t },
  } = useGetStartedBanner({ hasWellbeingProfile });

  return (
    <BackgroundHeader
      imageAlt={t("backgroundImageAlt")}
      imagePath="/img/employeeDashboard/get-started-banner-bg.jpg"
      showBackLink={false}
    >
      <div className={s.content}>
        <div className={cx(s.textWrapper)}>
          <Text
            color="white"
            fontFamily="centra"
            gutter="lg"
            size="5xl"
            weight="bold"
          >
            {bannerTitle}
          </Text>

          {isUserInTrialOrg && <VIPTrialSurvey />}

          <EmployeeNPSSurvey />

          {!hasWellbeingProfile && (
            <>
              <Text color="white" size="xl" gutter="md">
                {t("wellbeingProfileV2.text1")}
              </Text>
              <Text color="white" gutter="xl" size="sm" weight="medium">
                {t("wellbeingProfileV2.text2")}
              </Text>

              <div className="w-full lg:max-w-xs">
                <Button
                  width="full"
                  onClick={onGetStartedButtonClick}
                  variant="green"
                >
                  {t("buttonLabel")}
                </Button>
              </div>
            </>
          )}
        </div>
        {!hasWellbeingProfile && (
          <div className="mb-[-5rem] md:mb-[-7rem] w-full h-full order-first lg:order-last">
            <Image
              alt={t("wellbeingProfileV2.imageAlt")}
              src={"/img/employeeDashboard/wellbeing-profile-illustration.png"}
              width={500}
              height={500}
            />
          </div>
        )}
      </div>
    </BackgroundHeader>
  );
}
