import ctl from "@netlify/classnames-template-literals";

export const shapeWrapper = ctl(`
  relative
  flex
  min-h-[350px]
  h-[350px]
  min-w-[350px]
  w-[350px]
  items-center
  justify-center
  m-auto
  scale-90
  md:scale-100
  xl:m-0
`);

export const shapeContent = ctl(`
  absolute
  top-0
  left-0
  z-10
  flex
  h-full
  w-full
  items-center
  justify-center
`);

export const shapeInnerCircle = ctl(`
  flex
  h-28
  w-28
  flex-col
  items-center
  justify-center
  rounded-full
`);
