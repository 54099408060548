/**
 * @generated SignedSource<<c8a2082445bb9a6eab064af8a8145257>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AvailableLanguagesEnum = "ar" | "bg" | "bs" | "cmn" | "cnr" | "da" | "de" | "el" | "en" | "es" | "et" | "fil" | "fr" | "he" | "hi" | "hr" | "hu" | "id" | "it" | "ja" | "lt" | "lv" | "ms" | "nl" | "no" | "pl" | "pt" | "ro" | "ru" | "sk" | "sq" | "sr" | "sv" | "th" | "tr" | "uk" | "ur" | "yue";
export type SessionTypeEnum = "DROP_IN" | "MANAGER_ONE_TO_ONE" | "TEST" | "THERAPY";
import { FragmentRefs } from "relay-runtime";
export type BookingConfirmationDialogFragment_session$data = {
  readonly endDate: string;
  readonly hasPayment: boolean;
  readonly id: string;
  readonly language: AvailableLanguagesEnum;
  readonly professional: {
    readonly clinicalProfile: {
      readonly image: string | null | undefined;
      readonly professionalTitle: string | null | undefined;
    } | null | undefined;
    readonly name: string;
    readonly surname: string;
  } | null | undefined;
  readonly startDate: string;
  readonly type: SessionTypeEnum;
  readonly " $fragmentType": "BookingConfirmationDialogFragment_session";
};
export type BookingConfirmationDialogFragment_session$key = {
  readonly " $data"?: BookingConfirmationDialogFragment_session$data;
  readonly " $fragmentSpreads": FragmentRefs<"BookingConfirmationDialogFragment_session">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BookingConfirmationDialogFragment_session",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "language",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPayment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "professional",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "surname",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ClinicalProfile",
          "kind": "LinkedField",
          "name": "clinicalProfile",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "image",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "professionalTitle",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    }
  ],
  "type": "Session",
  "abstractKey": null
};

(node as any).hash = "e60477a38104ae110b18cae87d56225a";

export default node;
