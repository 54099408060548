import { useRouter } from "next/router";
import { CardSecondary, Button } from "@olivahealth/oli-ui";

import Image from "../../../components/common/Image";
import useTranslation from "../../../hooks/useTranslation";

export default function BookDropInSessionCard() {
  const { t } = useTranslation("employeeOneToOnePage");

  const { push } = useRouter();

  return (
    <CardSecondary
      title={t("bannerCheckIn.title")}
      description={t("bannerCheckIn.description")}
      button={
        <Button
          data-testid="book-one-to-one-card-button"
          variant="secondary"
          onClick={() => {
            push("/sessions?schedule=DROP_IN");
          }}
          width="full"
        >
          {t("bannerCheckIn.buttonLabel")}
        </Button>
      }
      image={
        <Image
          src="/img/img_calendar_dayone.png"
          alt={t("bannerCheckIn.imgAlt")}
          width={96}
          height={96}
        />
      }
    />
  );
}
