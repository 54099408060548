import cx from "classnames";
import { ReactElement } from "react";

import Image from "../Image";
import * as s from "./styles";

const LIGHT_BACKGROUND_COLOR = "white";

export interface PillarScore {
  pillar: string;
  score: number;
}

interface Props {
  innerCircleColor?: string;
  innerCircleContent: ReactElement;
  scores: readonly PillarScore[];
  variant?: "v1" | "v2";
}

export default function WellbeingProfileShape({
  variant = "v1",
  innerCircleColor = LIGHT_BACKGROUND_COLOR,
  innerCircleContent,
  scores,
}: Props) {
  const isBackgroundLight = innerCircleColor === LIGHT_BACKGROUND_COLOR;
  const showScores = scores.length > 0;

  const backgroundSource = getBackgroundSource(isBackgroundLight, variant);

  return (
    <div className={s.shapeWrapper}>
      <Image
        src={backgroundSource}
        alt="Oliva's wellbeing pillars"
        fill
        sizes="100vw"
      />
      <div className={s.shapeContent}>
        <div className={cx(s.shapeInnerCircle, `bg-${innerCircleColor}`)}>
          {innerCircleContent}
        </div>
      </div>
      {showScores && <Blob scores={scores} />}
    </div>
  );
}

/**
 * The magical numbers here are used to generate the path based on a 300x300 canvas
 * Ax|Ay are the coordinates of the starting point
 * a1x|a1y are the coordinates of the first handle
 * a2x|a2y are the coordinates of the second handle
 * @param scores
 * @constructor
 */
export function Blob({ scores }: { scores: Props["scores"] }) {
  const scoreEmotional =
    scores.find((score) => score.pillar === "EMOTIONAL")?.score ?? 0;
  const scoreProfessional =
    scores.find((score) => score.pillar === "PROFESSIONAL")?.score ?? 0;
  const scorePhysical =
    scores.find((score) => score.pillar === "PHYSICAL")?.score ?? 0;
  const scoreSocial =
    scores.find((score) => score.pillar === "SOCIAL")?.score ?? 0;
  const scoreSleep =
    scores.find((score) => score.pillar === "SLEEP")?.score ?? 0;
  const scoreFinancial =
    scores.find((score) => score.pillar === "FINANCIAL")?.score ?? 0;

  const Ax = 116 - scorePhysical * 0.52;
  const Ay = 103 - scorePhysical * 0.76;
  const a1x = 90 - scorePhysical * 0.81;
  const a1y = 122 - scorePhysical * 0.56;
  const a2x = 142 - scorePhysical * 0.29;
  const a2y = 86 - scorePhysical * 0.94;

  const Bx = 183 + (scoreSocial || scoreSleep) * 0.52;
  const By = 103 - (scoreSocial || scoreSleep) * 0.76;
  const b1x = 158 + (scoreSocial || scoreSleep) * 0.29;
  const b1y = 86 - (scoreSocial || scoreSleep) * 0.94;
  const b2x = 208 + (scoreSocial || scoreSleep) * 0.81;
  const b2y = 122 - (scoreSocial || scoreSleep) * 0.56;

  const Cx = 204 + scoreFinancial * 0.93;
  const Cy = 168 + scoreFinancial * 0.25;
  const c1x = 214 + scoreFinancial * 0.96;
  const c1y = 138;
  const c2x = 195 + scoreFinancial * 0.77;
  const c2y = 198 + scoreFinancial * 0.63;

  const Dx = 150;
  const Dy = 208 + scoreEmotional * 0.92;
  const d1x = 180 + scoreEmotional * 0.33;
  const d1y = 208 + scoreEmotional * 0.92;
  const d2x = 120 - scoreEmotional * 0.33;
  const d2y = 208 + scoreEmotional * 0.92;

  const Ex = 101 - scoreProfessional * 0.93;
  const Ey = 168 + scoreProfessional * 0.25;
  const e1x = 105 - scoreProfessional * 0.77;
  const e1y = 196 + scoreProfessional * 0.63;
  const e2x = 87 - scoreProfessional * 0.96;
  const e2y = 139;

  const svg =
    `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300" fill="none"
       id="wellbeingShape" class="rotate-180">
<defs>
  <pattern id="gradient" patternUnits="userSpaceOnUse" width="300" height="300">
    <image href="/img/img_wellbeing_shape_gradient.png" x="0" y="0" width="300" height="300" />
  </pattern>
</defs>` +
    `<path d="
         M ${Ax} ${Ay} 
         C ${a2x} ${a2y} ${b1x} ${b1y} ${Bx} ${By}  
         V ${By} 
         C ${b2x} ${b2y} ${c1x} ${c1y} ${Cx} ${Cy} 
         V ${Cy}  
         C ${c2x} ${c2y} ${d1x} ${d1y} ${Dx} ${Dy} 
         V ${Dy} 
         C ${d2x} ${d2y} ${e1x} ${e1y} ${Ex} ${Ey}
         V ${Ey} 
         C ${e2x} ${e2y} ${a1x} ${a1y} ${Ax} ${Ay}
         V ${Ay}  
         Z" fill="url(#gradient)"/>

  </svg>
`;

  return (
    <div
      dangerouslySetInnerHTML={{ __html: svg }}
      className="h-[300px] w-[300px]"
    />
  );
}

function getBackgroundSource(
  isBackgroundLight: boolean,
  variant: Props["variant"],
) {
  if (variant === "v1") {
    return isBackgroundLight
      ? "/img/img_wellbeing_shape_outside_light.png"
      : "/img/img_wellbeing_shape_outside_dark.png";
  }

  return isBackgroundLight
    ? "/img/img_new_wellbeing_shape_outside_light.png"
    : "/img/img_new_wellbeing_shape_outside_dark.png";
}
