import { graphql, useFragment } from "react-relay";
import { format, sub } from "date-fns";
import { getUserFullName } from "@olivahealth/utils/helpers/user";
import { DEFAULT_CANCELLATION_NOTICE_IN_DAYS_NEW } from "@olivahealth/constants";
import { useEffect } from "react";
import {
  Avatar,
  Button,
  Text,
  CalendarIcon,
  Dialog,
} from "@olivahealth/oli-ui";
import Image from "../../../components/common/Image";
import useTranslation from "../../../hooks/useTranslation";
import { useAmplitude } from "../../../services/contexts/AmplitudeContext";
import { formatDate, formatTime } from "../../../utils/date/format";
import { BookingConfirmationDialogFragment_session$key as Fragment } from "./__generated__/BookingConfirmationDialogFragment_session.graphql";
import { bookingConfirmationDialog as s } from "./styles";

const BookingConfirmationDialogFragment = graphql`
  fragment BookingConfirmationDialogFragment_session on Session {
    type
    language
    id
    hasPayment
    professional {
      name
      surname
      clinicalProfile {
        image
        professionalTitle
      }
    }
    startDate
    endDate
  }
`;

interface Props {
  data: Fragment;
  isOpen: boolean;
  onClose: () => void;
  onButtonClick: () => void;
}

export default function BookingConfirmationDialog({
  data,
  isOpen,
  onClose,
  onButtonClick,
}: Props): JSX.Element | null {
  const { t } = useTranslation("employeeDashboard");
  const { trackEvent } = useAmplitude();
  const fragmentData = useFragment<Fragment>(
    BookingConfirmationDialogFragment,
    data,
  );

  const { endDate, professional, startDate, type, language, hasPayment, id } =
    fragmentData;
  const professionalName = getUserFullName(professional);

  const cancellationDeadline = sub(new Date(startDate ?? ""), {
    days: DEFAULT_CANCELLATION_NOTICE_IN_DAYS_NEW,
  });

  useEffect(() => {
    trackEvent("Session booked", {
      serviceType: type,
      language: language,
      professional: professionalName,
      sessionId: id,
      isPaymentNeeded: hasPayment,
      actionedBy: "employee",
      isLateBooking: false,
    });
  }, [trackEvent, type, professionalName, hasPayment, id, language]);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title={t("bookingConfirmationDialog.title")}
    >
      <div className={s.contentWrapper}>
        {professional && (
          <div className="flex flex-col">
            <Text gutter="md">{t("bookingConfirmationDialog.hostInfo")}</Text>
            <div className="flex items-center gap-4">
              <div className="h-12 w-12">
                <Avatar addNoise variant="text" label={professional.name} />
              </div>
              <div className="flex flex-col">
                <Text weight="bold">{professionalName}</Text>
                {professional.clinicalProfile?.professionalTitle && (
                  <Text size="sm" color="secondary">
                    {professional.clinicalProfile?.professionalTitle}
                  </Text>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="flex flex-col gap-y-2">
          <Text>{t("bookingConfirmationDialog.eventTitle")}</Text>
          <div className="flex items-center">
            <div className={s.eventIconWrapper}>
              <Image
                src="/svg/ic_google_calendar_paths.svg"
                className="mr-4"
                alt={t("bookingConfirmationDialog.dateIconAltText")}
                fill
                sizes="5vw"
              />
            </div>
            <span className="text-neutral-900">
              <Text weight="bold">{formatDate(startDate)}</Text>
              {formatTime(startDate)}
              {" - "}
              {formatTime(endDate)}
            </span>
          </div>
          <span className="flex">
            <CalendarIcon className={s.infoIcon} />
            <Text size="xs" color="secondary">
              {t("bookingConfirmationDialog.infoText", {
                deadline: format(
                  cancellationDeadline,
                  "EEE, LLL dd 'at' HH:mm",
                ),
              })}
            </Text>
          </span>
        </div>
        <div className="flex justify-end">
          <span className="w-48">
            <Button variant="primary" onClick={onButtonClick}>
              {t("bookingConfirmationDialog.buttonLabel")}
            </Button>
          </span>
        </div>
      </div>
    </Dialog>
  );
}
