import { graphql, useFragment } from "react-relay";
import { isBefore, isToday as isTodayCheck } from "date-fns";
import SessionType from "@olivahealth/graphql-server/src/domain/value-objects/SessionType";
import SessionStatus from "@olivahealth/graphql-server/src/domain/value-objects/SessionStatus";
import { getDataProcessingConsent } from "@olivahealth/utils/helpers/session";

import { sessionSupportsAutomatedSummary } from "@olivahealth/utils/helpers/language";
import { OlivaHook } from "../../../hooks/OlivaHook";
import {
  useProfessionalSessionCard_fragment$key as IProfessionalSessionCardKeyFragment,
  SessionStatusEnum,
} from "./__generated__/useProfessionalSessionCard_fragment.graphql";

const ProfessionalSessionCardFragment = graphql`
  fragment useProfessionalSessionCard_fragment on SessionResponse {
    __typename
    ... on Session {
      id
      type
      checkoutUrl
      status
      endDate
      startDate
      employee {
        name
        surname
        settings {
          allowDataProcessing
        }
      }
      appointment {
        rescheduledPreviousStartDate
      }
      cancelledByName
      cancelledById
      settings {
        allowDataProcessing
      }
      automatedSummary {
        summary {
          ... on ProcessedProfessionalSessionSummary {
            __typename
          }
        }
      }
      language
      transcriptionStatus
    }
    ... on GroupSession {
      id
      status
      endDate
      startDate
      workshop {
        title
        illustrationCard
      }
      participants {
        id
      }
      cancelledByName
      cancelledById
      language
    }
  }
`;

export interface UseProfessionalSessionCardData {
  isGroupSession: boolean;
  isSession: boolean;
  isCheckInSession: boolean;
  isCancelled: boolean;
  isAwaitingPayment: boolean;
  isPast: boolean;
  isOngoing: boolean;
  status: SessionStatusEnum;
  startDate: string;
  endDate: string;
  cancelledById?: string | null;
  cancelledByName?: string | null;
  sessionId: string;
  linkHref: string;
  title: string;
  rescheduledPreviousStartDate: string | null;
  illustrationSrc: string | null;
  participantsCount: number;
  employee: { readonly name: string; readonly surname: string } | null;
  hasData: boolean;
  employeeConsent: boolean | null;
  isToday: boolean;
  hasSummaryAvailableToView: boolean;
  sessionLanguageSupportsAutomatedSummary: boolean;
  transcriptionHasError: boolean;
}

export interface EmptyData {
  hasData: boolean;
}

type UseProfessionalSessionCard = OlivaHook<
  UseProfessionalSessionCardData | EmptyData
>;

interface Props {
  session: IProfessionalSessionCardKeyFragment | null;
}

export default function useProfessionalSessionCard({
  session,
}: Props): UseProfessionalSessionCard {
  const data = useFragment<IProfessionalSessionCardKeyFragment>(
    ProfessionalSessionCardFragment,
    session,
  );

  if (session == null || data == null || data.__typename === "%other") {
    return {
      status: "success",
      data: {
        hasData: false,
      },
      effects: {},
    };
  }

  const isGroupSession = data.__typename === "GroupSession";
  const isSession = data.__typename === "Session";

  const { id, status, startDate, endDate, cancelledById, cancelledByName } =
    data;

  const type = isSession ? data.type : null;
  const appointment: { readonly rescheduledPreviousStartDate?: string | null } =
    isSession ? data.appointment : { rescheduledPreviousStartDate: null };

  const isCheckInSession = type === SessionType.DROP_IN;

  const isCancelled = (
    [SessionStatus.CANCELLED, SessionStatus.CANCELLED_LATE] as SessionStatus[]
  ).includes(status as SessionStatus);

  const isAwaitingPayment =
    isSession &&
    status === SessionStatus.AWAITING_PAYMENT &&
    data.checkoutUrl != null;

  const isPast =
    isBefore(new Date(endDate), new Date()) && status !== SessionStatus.ACTIVE;
  const isOngoing = status === SessionStatus.ACTIVE;
  const isToday = Boolean(startDate) && isTodayCheck(new Date(startDate));
  const employeeConsent = isSession
    ? getDataProcessingConsent({
        employeeSettings: data.employee?.settings,
        sessionSettings: data.settings,
        sessionEndDate: endDate,
        sessionStatus: status,
      }) ?? null
    : null;

  const hasSummaryAvailableToView = isSession && Boolean(data.automatedSummary);

  const sessionLanguageSupportsAutomatedSummary =
    sessionSupportsAutomatedSummary(data.language);

  const transcriptionHasError =
    (isSession &&
      data.transcriptionStatus &&
      ["FETCHING_ERROR", "PROCESSED_WITH_ERRORS"].includes(
        data.transcriptionStatus,
      )) ??
    false;

  return {
    status: "success",
    data: {
      hasData: true,
      isGroupSession,
      isSession,
      isCheckInSession,
      isCancelled,
      isAwaitingPayment,
      isPast,
      isOngoing,
      status,
      startDate,
      endDate,
      cancelledById,
      cancelledByName,
      sessionId: id,
      linkHref: `/sessions/${id}`,
      title: (isGroupSession && data.workshop?.title) || "",
      rescheduledPreviousStartDate:
        (isSession && appointment?.rescheduledPreviousStartDate) || null,
      illustrationSrc:
        (isGroupSession && data.workshop?.illustrationCard) || null,
      participantsCount: (isGroupSession && data.participants?.length) || 0,
      employee: (isSession && data.employee) || null,
      employeeConsent,
      isToday,
      hasSummaryAvailableToView,
      sessionLanguageSupportsAutomatedSummary,
      transcriptionHasError,
    },
    effects: {},
  };
}
