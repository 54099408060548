import ctl from "@netlify/classnames-template-literals";

export const wrapper = ctl(`
  flex
  h-full
  w-full
  items-center
  justify-center
  rounded-full
  font-bold
  text-white
  bg-gradient-to-br
  from-purple-400
  to-indigo-900
`);
