import SessionLanguage from "@olivahealth/graphql-server/src/domain/value-objects/SessionLanguage";
import { automatedSummarySupportedLanguages } from "@olivahealth/graphql-server/src/utils/Language";

export const sessionSupportsAutomatedSummary = (
  sessionLanguage?: SessionLanguage | string,
) => {
  if (!sessionLanguage) {
    return false;
  }

  return Boolean(
    automatedSummarySupportedLanguages.find(
      (lang) => lang.code === sessionLanguage,
    ),
  );
};
