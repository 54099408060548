import cx from "classnames";
import * as s from "./styles";

interface IProps {
  name?: string;
  textSize?: "small" | "medium";
}

export default function UserPlaceholder({ name, textSize = "medium" }: IProps) {
  return (
    <div
      className={cx(s.wrapper, {
        "text-xs": textSize === "small",
        "text-2xl": textSize === "medium",
      })}
    >
      {name && <span>{name.charAt(0).toLocaleUpperCase()}</span>}
    </div>
  );
}
