import { Text } from "@olivahealth/oli-ui";

import Image from "../../../components/common/Image";
import useTranslation from "../../../hooks/useTranslation";
import { DropdownState } from "./sharedTypes";

import * as s from "./styles";

interface Props {
  dropdownState: DropdownState;
}

export default function NoSessions({ dropdownState }: Props) {
  const { t } = useTranslation("professionalDashboard", {
    keyPrefix: "noSessions",
  });

  return (
    <div className={s.noSessionsWrapper}>
      <Image
        priority
        src="/img/img_drip_placeholder.png"
        alt={t("imageAlt")}
        width={236}
        height={132}
      />
      <div className="mb-4">
        <Text size="xl" weight="bold" align="center">
          {t(`${dropdownState}.title`)}
        </Text>
      </div>
      <Text align="center"> {t(`${dropdownState}.description`)}</Text>
    </div>
  );
}
