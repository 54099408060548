import { Text } from "@olivahealth/oli-ui";
import { getUserFullName } from "@olivahealth/utils/helpers/user";

import UserPlaceholder from "../../../components/common/UserPlaceholder";

import * as s from "./styles";

interface Props {
  employee?: { name: string; surname: string } | null;
}

export default function SessionCardEmployeeDetails({ employee }: Props) {
  return (
    <div className="flex">
      <div className={s.userPlaceholderWrapper}>
        <UserPlaceholder name={employee?.name} textSize="small" />
      </div>
      <Text>
        <span className={s.userNameWrapper}>{getUserFullName(employee)}</span>
      </Text>
    </div>
  );
}
