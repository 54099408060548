import { Text } from "@olivahealth/oli-ui";

import Image from "../../../components/common/Image";
import useTranslation from "../../../hooks/useTranslation";

import * as s from "./styles";

export default function NoSessionsToday() {
  const { t } = useTranslation("professionalDashboard", {
    keyPrefix: "noSessionsToday",
  });

  return (
    <div className={s.noSessionsTodayWrapper}>
      <Image
        src="/img/placeholder_calendar_default.png"
        alt={t("imgAlt")}
        className="object-cover"
        height={96}
        width={96}
      />
      <div className={s.noSessionsTodayTextWrapper}>
        <Text color="secondary" weight="bold" gutter="sm">
          {t("title")}
        </Text>
        <Text color="secondary">{t("description")}</Text>
      </div>
    </div>
  );
}
