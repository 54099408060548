import { useIntercom } from "react-use-intercom";
import { Button, Text } from "@olivahealth/oli-ui";
import Image from "../../../components/common/Image";
import useTranslation from "../../../hooks/useTranslation";
import { outlineCard as s } from "./styles";

export default function FindingMatchCard() {
  const { t } = useTranslation("employeeDashboard", {
    keyPrefix: "findingMatchCard",
  });
  const { show } = useIntercom();

  return (
    <div className={s.wrapper}>
      <div className="relative h-16 w-2/12 self-start">
        <Image
          src="/img/img_one_to_one_faces.png"
          alt={t("imageAltText")}
          fill
          sizes="30vw"
          className="object-contain"
        />
      </div>
      <div className="flex w-10/12 flex-col gap-y-3">
        <Text variant="h3">{t("title")}</Text>
        <Text color="secondary">{t("subtitle")}</Text>
        <span className="flex flex-col gap-x-3 md:flex-row">
          <Button onClick={show} variant="tertiary">
            {t("buttonLabelChat")}
          </Button>
        </span>
      </div>
    </div>
  );
}
