import shuffleArray from "@olivahealth/utils/shuffleArray";
import { graphql, useFragment } from "react-relay";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Mousewheel, Navigation } from "swiper/modules";
import { useMediaQuery } from "react-responsive";
import ResourceCard from "../../../components/common/ResourceCard";
import {
  SelfGuided_items$data as FragmentData,
  SelfGuided_items$key as IFragment,
} from "./__generated__/SelfGuided_items.graphql";
import * as s from "./styles";
import "swiper/css";

interface IProps {
  items: IFragment;
}

type Classes = FragmentData["selfGuidedWorkshops"]["nodes"];
type Resources = FragmentData["selfGuidedResources"]["nodes"];

const SelfGuidedFragment = graphql`
  fragment SelfGuided_items on Query {
    selfGuidedWorkshops: workshops(
      first: 2
      onlyWithUpcomingEvents: true
      filterBy: { type: TALK }
      sortBy: { randomly: true }
      isFeatured: false
    ) {
      nodes {
        id
        ...useResourceCard_data
      }
    }
    selfGuidedResources: resources(
      filterBy: { status: PUBLISHED, type: [AUDIO, PODCAST, VIDEO] }
      first: 4
    ) {
      nodes {
        id
        ...useResourceCard_data
      }
    }
  }
`;

const MAX_ITEMS = 4;

export default function SelfGuided({ items }: IProps): JSX.Element {
  const data = useFragment<IFragment>(SelfGuidedFragment, items);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

  if (isTabletOrMobile)
    return (
      <Swiper
        grabCursor
        modules={[A11y, Mousewheel, Navigation]}
        mousewheel={{
          releaseOnEdges: true,
        }}
        spaceBetween={16}
        slidesPerView={1.5}
        threshold={20}
        className="flex"
      >
        {getContent(
          data.selfGuidedWorkshops.nodes,
          data.selfGuidedResources.nodes,
        ).map((item) => (
          <SwiperSlide key={item.id} style={{ height: "auto" }}>
            <ResourceCard item={item} />
          </SwiperSlide>
        ))}
      </Swiper>
    );

  return (
    <div className={s.selfGuidedWrapper}>
      {getContent(
        data.selfGuidedWorkshops.nodes,
        data.selfGuidedResources.nodes,
      ).map((item) => (
        <ResourceCard key={item.id} item={item} />
      ))}
    </div>
  );
}

function getContent(
  workshops: Classes,
  resources: Resources,
  maxCount = MAX_ITEMS,
) {
  return shuffleArray([...workshops, ...resources]).slice(0, maxCount);
}
