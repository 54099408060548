import { times } from "lodash";
import { ReactElement } from "react";
import { useMediaQuery } from "react-responsive";
import {
  Badge,
  CheckCircleIcon,
  CircleIcon,
  Text,
  TextColor,
} from "@olivahealth/oli-ui";
import MentalHealthPillars from "@olivahealth/graphql-server/src/domain/value-objects/MentalHealthPillars";
import { BadgeVariant } from "@olivahealth/oli-ui/src/atoms/Badge";

import WellbeingProfileShape from "../../../components/common/WellbeingProfileShape/WellbeingProfileShape";
import useTranslation from "../../../hooks/useTranslation";
import { getTriagingPillarsRebrandVariants } from "../../../rebrand/pages/Triaging/triagingData";
import { wellbeingProfileCard as s } from "./styles";

interface IProps {
  description: ReactElement;
  footer?: ReactElement | null;
  innerCircleColor?: string;
  innerCircleContent: ReactElement;
  noFocusAreasTitle: string;
  noAreasOnTrackTitle: string;
  scores: ReadonlyArray<{
    needsFocus: boolean;
    pillar: MentalHealthPillars;
    score: number;
  }>;
  textColor?: TextColor;
  title: string;
}

export default function WellbeingProfilePanel({
  description,
  footer,
  innerCircleColor = "white",
  innerCircleContent,
  noFocusAreasTitle,
  noAreasOnTrackTitle,
  scores,
  textColor,
  title,
}: IProps) {
  const { t } = useTranslation(["organisationReports", "common"]);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1280px)" });

  const focusAreas = scores
    .filter(({ needsFocus }) => needsFocus)
    .map((score) => score.pillar);
  const areasOnTrack = scores
    .filter(({ needsFocus }) => !needsFocus)
    .map((score) => score.pillar);

  const hasData = scores.length > 0;

  return (
    <section>
      <Text color={textColor} gutter="sm" variant="h3">
        {title}
      </Text>
      <div className={s.wrapper} data-testid="wellbeing-profile-card">
        <div className={s.leftPanelWrapper}>
          <div className="mb-7 flex flex-col">
            <span className="mb-12 flex items-center gap-x-1">
              {description}
            </span>

            <Text variant="label" gutter="sm" color={textColor ?? "secondary"}>
              {t("aggregatedWellbeing.focusAreaTitle", {
                count: focusAreas.length,
              })}
            </Text>
            <span className={s.pillarTagsRow}>
              {focusAreas.length ? (
                focusAreas.map((area) => (
                  <Badge
                    icon={<CircleIcon variant="dashed" />}
                    key={area}
                    variant={
                      getTriagingPillarsRebrandVariants[area] as BadgeVariant
                    }
                  >
                    {t(`mentalHealthPillars.${area}`, {
                      ns: "common",
                    })}
                  </Badge>
                ))
              ) : hasData ? (
                <Text color={textColor ?? "secondary"} size="sm">
                  {noFocusAreasTitle}
                </Text>
              ) : (
                renderEmptyBadges(2)
              )}
            </span>
            <Text variant="label" gutter="sm" color={textColor ?? "secondary"}>
              {t("aggregatedWellbeing.onTrackAreaTitle", {
                count: areasOnTrack.length,
              })}
            </Text>
            <span className={s.pillarTagsRow}>
              {areasOnTrack.length ? (
                areasOnTrack.map((area) => (
                  <Badge
                    key={area}
                    variant={
                      getTriagingPillarsRebrandVariants[area] as BadgeVariant
                    }
                    icon={<CheckCircleIcon />}
                  >
                    {t(`mentalHealthPillars.${area}`, {
                      ns: "common",
                    })}
                  </Badge>
                ))
              ) : hasData ? (
                <Text color={textColor ?? "secondary"} size="sm">
                  {noAreasOnTrackTitle}
                </Text>
              ) : (
                renderEmptyBadges(3)
              )}
            </span>
          </div>

          {footer && <div className="flex flex-wrap gap-x-1">{footer}</div>}
        </div>

        <div
          className={
            isSmallScreen
              ? "my-5 flex scale-90 md:my-0"
              : "-my-[5%] w-2/5 scale-[0.8] lg:flex"
          }
        >
          <WellbeingProfileShape
            innerCircleColor={innerCircleColor}
            innerCircleContent={innerCircleContent}
            scores={scores}
          />
        </div>
      </div>
    </section>
  );
}

function renderEmptyBadges(count) {
  return times(count, (index) => <Badge variant="empty" key={index} />);
}
