import { ReactNode } from "react";
import { Text } from "@olivahealth/oli-ui";

import SessionCard from "../../organisms/ProfessionalSessionCard/SessionCard";
import { Sessions } from "./useProfessionalDashboard";

import * as s from "./styles";

interface SessionListProps {
  sessions: Sessions;
  label: string;
  emptyListState?: ReactNode;
}

export default function SessionList({
  sessions,
  label,
  emptyListState = null,
}: SessionListProps) {
  return (
    <div className="mb-12">
      <Text variant="h3"> {label} </Text>
      {sessions?.length ? (
        <div className={s.sessionsCardWrapper}>
          {sessions.map((session) => (
            <SessionCard key={session.id} session={session} />
          ))}
        </div>
      ) : (
        emptyListState
      )}
    </div>
  );
}
