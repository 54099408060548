import { MessageBanner } from "@olivahealth/oli-ui";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import Image from "../../../components/common/Image";

interface Props {
  organisationName: string;
}

export default function AddPersonalEmailBanner({ organisationName }: Props) {
  const { t } = useTranslation("common", {
    keyPrefix: "addPersonalEmailBanner",
  });

  const { push } = useRouter();

  return (
    <MessageBanner
      isOpen
      icon={
        <Image
          alt={t("altTextIcon")}
          src="/img/img_envelope_danger.png"
          height={90}
          width={90}
        />
      }
      primaryAction={{
        action: () => {
          push("/profile");
        },
        title: t("buttonText"),
      }}
      message={t("title")}
      description={t("description", { organisation: organisationName })}
      showCloseButton={false}
      variant="info"
    />
  );
}
