import ctl from "@netlify/classnames-template-literals";

export const upcomingEvents = {
  emptyStateWrapper: ctl(`
    flex
    flex-col
    items-start
    md:flex-row
    md:items-center
  `),
};

export const wellbeingProfileCard = {
  wrapper: ctl(`
    flex
    flex-col
    w-full
    rounded-xl
    lg:flex-row
  `),
  leftPanelWrapper: ctl(`
    flex
    w-full
    flex-col
    justify-between
    p-6
    lg:p-0
    lg:w-3/5
  `),
  pillarTagsRow: ctl(`
    mb-6
    flex
    flex-wrap
    gap-x-4
    gap-y-2
  `),
};
