import { graphql, useLazyLoadQuery } from "react-relay";
import { isToday } from "date-fns";

import SessionStatus from "@olivahealth/graphql-server/src/domain/value-objects/SessionStatus";

import { OlivaHook } from "../../../hooks/OlivaHook";
import { useUserData } from "../../../services/contexts/UserDataContext";
import { useProfessionalDashboardQuery as IUseProfessionalDashboardQuery } from "./__generated__/useProfessionalDashboardQuery.graphql";

const useProfessionalDashboardQuery = graphql`
  query useProfessionalDashboardQuery(
    $userId: String!
    $includePast: Boolean!
  ) {
    upcoming: sessions(
      filterBy: { userId: $userId }
      include: { cancelled: true }
      only: UPCOMING
      sortBy: { scheduledDate: ASC }
    ) {
      nodes {
        __typename
        ...useProfessionalSessionCard_fragment
        ... on Session {
          id
          status
          startDate
        }
        ... on GroupSession {
          id
          status
          startDate
        }
      }
    }
    past: sessions(
      filterBy: { userId: $userId }
      include: { cancelled: true }
      only: PAST
      sortBy: { scheduledDate: DESC }
    ) @include(if: $includePast) {
      nodes {
        __typename
        ...useProfessionalSessionCard_fragment
        ... on Session {
          id
          status
        }
        ... on GroupSession {
          id
          status
        }
      }
    }
  }
`;

type NodesOrNull<T> = T extends { nodes: infer Nodes } ? Nodes : null;

export type Sessions =
  | NodesOrNull<IUseProfessionalDashboardQuery["response"]["past"]>
  | NodesOrNull<IUseProfessionalDashboardQuery["response"]["upcoming"]>;

export interface UseProfessionalDashboardData {
  sessionsToday: IUseProfessionalDashboardQuery["response"]["upcoming"]["nodes"];
  pastSessions: NodesOrNull<IUseProfessionalDashboardQuery["response"]["past"]>;
  upcomingSessions: IUseProfessionalDashboardQuery["response"]["upcoming"]["nodes"];
  pastSessionsLength: number;
  upcomingSessionsLength: number;
}

type UseProfessionalDashboard = OlivaHook<UseProfessionalDashboardData>;

export default function useProfessionalDashboard(
  includePast?: boolean,
): UseProfessionalDashboard {
  const { data: user } = useUserData();

  const data = useLazyLoadQuery<IUseProfessionalDashboardQuery>(
    useProfessionalDashboardQuery,
    {
      userId: user?.id ?? "",
      includePast: includePast ?? false,
    },
  );

  const pastSessions = data.past
    ? data.past.nodes.filter((session) => Object.keys(session).length !== 0)
    : [];
  const upcomingSessions = data.upcoming
    ? data.upcoming.nodes.filter((session) => Object.keys(session).length !== 0)
    : [];

  const sessionsToday = upcomingSessions.filter((session) => {
    const isActive = session.status === SessionStatus.ACTIVE;
    const today = session.startDate && isToday(new Date(session.startDate));
    return isActive || today;
  });

  const notActiveUpcomingSessionsAfterToday = upcomingSessions.filter(
    (session) => {
      const notActive = session.status !== SessionStatus.ACTIVE;
      const notToday =
        session.startDate && !isToday(new Date(session.startDate));
      return notActive && notToday;
    },
  );

  return {
    status: "success",
    data: {
      sessionsToday,
      pastSessions,
      upcomingSessions: notActiveUpcomingSessionsAfterToday,
      pastSessionsLength: pastSessions.length || 0,
      upcomingSessionsLength: notActiveUpcomingSessionsAfterToday.length || 0,
    },
    effects: {},
  };
}
