import SessionLanguage from "../domain/value-objects/SessionLanguage";

export class Language {
  static readonly ARABIC = new Language("ar", "Arabic");
  static readonly ENGLISH = new Language("en", "English");
  static readonly FRENCH = new Language("fr", "French");
  static readonly GERMAN = new Language("de", "German");
  static readonly HEBREW = new Language("he", "Hebrew");
  static readonly HINDI = new Language("hi", "Hindi");
  static readonly ITALIAN = new Language("it", "Italian");
  static readonly PORTUGUESE = new Language("pt", "Portuguese");
  static readonly RUSSIAN = new Language("ru", "Russian");
  static readonly SPANISH = new Language("es", "Spanish");
  static readonly UKRAINIAN = new Language("uk", "Ukrainian");
  static readonly URDU = new Language("ur", "Urdu");
  static readonly POLISH = new Language("pl", "Polish");
  static readonly GREEK = new Language("el", "Greek");
  static readonly ROMANIAN = new Language("ro", "Romanian");
  static readonly SERBIAN = new Language("sr", "Serbian");
  static readonly INDONESIAN = new Language("id", "Indonesian");
  static readonly MANDARIN = new Language("cmn", "Mandarin");
  static readonly CANTONESE = new Language("yue", "Cantonese");
  static readonly HUNGARIAN = new Language("hu", "Hungarian");
  static readonly DUTCH = new Language("nl", "Dutch");
  static readonly CROATIAN = new Language("hr", "Croatian");
  static readonly BULGARIAN = new Language("bg", "Bulgarian");
  static readonly ESTONIAN = new Language("et", "Estonian");
  static readonly LATVIAN = new Language("lv", "Latvian");
  static readonly LITHUANIAN = new Language("lt", "Lithuanian");
  static readonly BOSNIAN = new Language("bs", "Bosnian");
  static readonly SLOVAKIAN = new Language("sk", "Slovakian");
  static readonly MONTENEGRIN = new Language("cnr", "Montenegrin");
  static readonly TURKISH = new Language("tr", "Turkish");
  static readonly ALBANIAN = new Language("sq", "Albanian");
  static readonly THAI = new Language("th", "Thai");
  static readonly MALAY = new Language("ms", "Malay");
  static readonly FILIPINO = new Language("fil", "Filipino");
  static readonly JAPANESE = new Language("ja", "Japanese");
  static readonly NORWEGIAN = new Language("no", "Norwegian");
  static readonly SWEDISH = new Language("sv", "Swedish");
  static readonly DANISH = new Language("da", "Danish");

  private constructor(
    public readonly code: SessionLanguage,
    public readonly label: string,
  ) {}

  public static findLanguageByCode = (
    languageCode: string,
  ): Language | null => {
    return Object.values(Language).find(
      (it) => it.code?.toUpperCase() === languageCode?.toUpperCase(),
    );
  };

  public static findLanguageByLabel = (
    languageLabel: string,
  ): Language | null => {
    if (!languageLabel) {
      return null;
    }

    return Object.values(Language).find(
      (it) => it.label?.toUpperCase() === languageLabel.toUpperCase(),
    );
  };
}

export const bookableLanguages = [
  Language.ARABIC,
  Language.ENGLISH,
  Language.FRENCH,
  Language.GERMAN,
  Language.HEBREW,
  Language.HINDI,
  Language.ITALIAN,
  Language.PORTUGUESE,
  Language.RUSSIAN,
  Language.SPANISH,
  Language.UKRAINIAN,
  Language.URDU,
  Language.POLISH,
  Language.GREEK,
  Language.ROMANIAN,
  Language.SERBIAN,
  Language.INDONESIAN,
  Language.MANDARIN,
  Language.CANTONESE,
  Language.HUNGARIAN,
  Language.DUTCH,
  Language.CROATIAN,
  Language.BULGARIAN,
  Language.ESTONIAN,
  Language.LATVIAN,
  Language.LITHUANIAN,
  Language.BOSNIAN,
  Language.SLOVAKIAN,
  Language.MONTENEGRIN,
  Language.TURKISH,
  Language.ALBANIAN,
  Language.THAI,
  Language.MALAY,
  Language.FILIPINO,
  Language.JAPANESE,
  Language.NORWEGIAN,
  Language.SWEDISH,
  Language.DANISH,
];

export const automatedSummarySupportedLanguages = [
  Language.DANISH,
  Language.DUTCH,
  Language.ENGLISH,
  Language.FRENCH,
  Language.GERMAN,
  Language.HINDI,
  Language.INDONESIAN,
  Language.ITALIAN,
  Language.JAPANESE,
  Language.NORWEGIAN,
  Language.POLISH,
  Language.PORTUGUESE,
  Language.RUSSIAN,
  Language.SPANISH,
  Language.SWEDISH,
  Language.TURKISH,
  Language.UKRAINIAN,
];
