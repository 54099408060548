import { useEffect, useRef, useState } from "react";
import { SURVEY_PROFESSIONAL_NPS_QUESTION_ID_SCORE } from "@olivahealth/constants";

import { NativeSurveyVariables } from "../../../../components/common/NativeSurvey";
import {
  NativeSurveyAnswers,
  NativeSurveyFormInitialState,
  OnAnswersChanged,
  SurveyFlowToEventsMapping,
} from "../../../../components/common/NativeSurvey/useNativeSurvey";
import { OlivaHook } from "../../../../hooks/OlivaHook";
import { useAmplitude } from "../../../../services/contexts/AmplitudeContext";
import {
  defaultSharedStoreContext,
  ISharedStoreContext,
  useSharedStoreContext,
} from "../../../../services/contexts/SharedStoreContext";
import { useUserData } from "../../../../services/contexts/UserDataContext";
import { setLocalStorageDismissedAt } from "../../../../utils/survey/nps-helper";

type ProfessionalNPSSurveyFormStatus =
  | "OPENED"
  | "DISMISSED"
  | "COMPLETED"
  | "CLOSED";

interface UseProfessionalNPSSurveyData {
  formInitialState?: NativeSurveyFormInitialState;
  formStatus: ProfessionalNPSSurveyFormStatus;
  pendingSubmissionId: string | null;
  surveyFlowToEventsMapping: SurveyFlowToEventsMapping;
  variables: NativeSurveyVariables;
}

interface UseProfessionalNPSSurveyEffects {
  onAnswersChanged?: OnAnswersChanged;
  onDismissButtonClick: () => void;
  onSubmitSuccess: () => void;
}

type UseProfessionalNPSSurvey = OlivaHook<
  UseProfessionalNPSSurveyData,
  UseProfessionalNPSSurveyEffects
>;

export default function useProfessionalNPSSurvey(): UseProfessionalNPSSurvey {
  const { trackEvent } = useAmplitude();
  const {
    surveyProfessionalNPS: { pendingSubmissionId, formInitialState },
    setSharedStore,
  } = useSharedStoreContext();
  const [formStatus, setFormStatus] = useState<ProfessionalNPSSurveyFormStatus>(
    pendingSubmissionId ? "OPENED" : "CLOSED",
  );
  const [variables, setVariables] = useState<NativeSurveyVariables>({});
  const latestSurveyContextState =
    useRef<ISharedStoreContext["surveyProfessionalNPS"]>();
  const { refetchData: refetchUserData } = useUserData();

  const surveyFlowToEventsMapping: SurveyFlowToEventsMapping = {
    onSurveyOpened: [
      {
        name: "NPS survey viewed",
        eventProperties: {
          surveyType: "MHP_NPS",
        },
      },
    ],
    onQuestionAnswered: {
      [SURVEY_PROFESSIONAL_NPS_QUESTION_ID_SCORE]: [
        {
          name: "NPS survey started",
          eventProperties: {
            surveyType: "MHP_NPS",
            submissionId: pendingSubmissionId,
          },
        },
      ],
    },
    onSurveyCompleted: [
      {
        name: "NPS survey finished",
        eventProperties: {
          surveyType: "MHP_NPS",
        },
      },
    ],
  };

  const emptySurveyContextState = () => {
    latestSurveyContextState.current =
      defaultSharedStoreContext.surveyProfessionalNPS;

    setSharedStore({
      surveyProfessionalNPS: latestSurveyContextState.current,
    });
  };

  const onDismissButtonClick = () => {
    setFormStatus("DISMISSED");
    setLocalStorageDismissedAt("professional");
    emptySurveyContextState();
    trackEvent("NPS survey dismissed", {
      surveyType: "MHP_NPS",
      submissionId: pendingSubmissionId,
    });
  };

  const onAnswersChanged = (
    answers: NativeSurveyAnswers,
    currentQuestionIndex: number,
  ) => {
    latestSurveyContextState.current = {
      pendingSubmissionId,
      formInitialState: {
        initialAnswers: answers,
        currentQuestionIndex,
      },
    };

    if (currentQuestionIndex === 1) {
      const newScore = answers.get("score")?.chosenOption.value;

      if (newScore !== variables.score) {
        setVariables({
          score: newScore,
        });
      }
    }
  };

  const onSubmitSuccess = () => {
    setFormStatus("COMPLETED");
    emptySurveyContextState();
    refetchUserData();
  };

  useEffect(() => {
    return () => {
      if (latestSurveyContextState.current?.pendingSubmissionId) {
        setSharedStore({
          surveyProfessionalNPS: latestSurveyContextState.current,
        });
      }
    };
  }, []);

  return {
    status: "success",
    data: {
      formInitialState,
      formStatus,
      pendingSubmissionId,
      surveyFlowToEventsMapping,
      variables,
    },
    effects: {
      onAnswersChanged,
      onDismissButtonClick,
      onSubmitSuccess,
    },
  };
}
