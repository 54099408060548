/**
 * @generated SignedSource<<a0c9e902871651df694eb71362260d51>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SelfGuided_items$data = {
  readonly selfGuidedResources: {
    readonly nodes: ReadonlyArray<{
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"useResourceCard_data">;
    }>;
  };
  readonly selfGuidedWorkshops: {
    readonly nodes: ReadonlyArray<{
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"useResourceCard_data">;
    }>;
  };
  readonly " $fragmentType": "SelfGuided_items";
};
export type SelfGuided_items$key = {
  readonly " $data"?: SelfGuided_items$data;
  readonly " $fragmentSpreads": FragmentRefs<"SelfGuided_items">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "useResourceCard_data"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SelfGuided_items",
  "selections": [
    {
      "alias": "selfGuidedWorkshops",
      "args": [
        {
          "kind": "Literal",
          "name": "filterBy",
          "value": {
            "type": "TALK"
          }
        },
        {
          "kind": "Literal",
          "name": "first",
          "value": 2
        },
        {
          "kind": "Literal",
          "name": "isFeatured",
          "value": false
        },
        {
          "kind": "Literal",
          "name": "onlyWithUpcomingEvents",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "sortBy",
          "value": {
            "randomly": true
          }
        }
      ],
      "concreteType": "WorkshopConnection",
      "kind": "LinkedField",
      "name": "workshops",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Workshop",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": "workshops(filterBy:{\"type\":\"TALK\"},first:2,isFeatured:false,onlyWithUpcomingEvents:true,sortBy:{\"randomly\":true})"
    },
    {
      "alias": "selfGuidedResources",
      "args": [
        {
          "kind": "Literal",
          "name": "filterBy",
          "value": {
            "status": "PUBLISHED",
            "type": [
              "AUDIO",
              "PODCAST",
              "VIDEO"
            ]
          }
        },
        {
          "kind": "Literal",
          "name": "first",
          "value": 4
        }
      ],
      "concreteType": "ResourceConnection",
      "kind": "LinkedField",
      "name": "resources",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Resource",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": "resources(filterBy:{\"status\":\"PUBLISHED\",\"type\":[\"AUDIO\",\"PODCAST\",\"VIDEO\"]},first:4)"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "1a386f4d8b763f599dce6cde34dbbc15";

export default node;
