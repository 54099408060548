import ctl from "@netlify/classnames-template-literals";

// SessionCard

export const cardWrapper = ctl(`
  max-w-[38rem]
`);

export const cardContent = ctl(`
  flex
  flex-col
  lg:flex-row
  p-2
  lg:pr-4
`);

export const imageWrapper = ctl(`
  min-w-[140px]
  flex
  items-center
`);

export const detailsWrapper = (isGreyedOut: boolean) =>
  ctl(`
    ml-2 
    flex
    w-full
    flex-col 
    justify-between 
    sm:ml-6
    ${isGreyedOut ? "text-neutral-500" : ""}
`);

export const bottomRowWrapper = ctl(`
  flex
  flex-col-reverse
  justify-between
  lg:flex-row
`);

export const checkInLabelWrapper = ctl(`
  mb-2
  lg:mb-0
`);

// SessionCardTags

export const tagWrapper = (mobileView: boolean) => {
  if (mobileView) {
    return ctl(`
      lg:hidden
    `);
  }

  return ctl(`
      mb-2
      hidden
      lg:block
    `);
};

export const tagLabel = ctl(`
  max-w-[150px]
  overflow-hidden
  whitespace-nowrap
  text-ellipsis
`);

// SessionDetails

export const groupSessionDetailsWrapper = ctl(`
  flex
  flex-col 
  lg:flex-[0.9]
`);

export const groupSessionRow = ctl(`
  flex
  mb-3
`);

export const sessionDetailsWrapper = ctl(`
  flex
  lg:flex-[0.9]
`);

export const desktopIconWrapper = ctl(`
  mr-3
  hidden
  lg:flex
`);

export const rescheduledTagWrapper = ctl(`
  flex
  hidden
  items-center
  lg:block
`);

// SessionCardCTA

export const ctaWrapper = (mobileView: boolean) => {
  if (mobileView) {
    return ctl(`mt-2 lg:hidden`);
  }

  return ctl(`mb-2 hidden lg:block`);
};

// SessionCardEmployeeDetails

export const userPlaceholderWrapper = ctl(`
  mr-3
  h-6
  w-6
  flex-shrink-0
`);

export const userNameWrapper = ctl(`
  block
  max-w-[200px]
  overflow-hidden
  text-ellipsis
  whitespace-nowrap
`);
