import { useRouter } from "next/router";
import { graphql, useFragment } from "react-relay";
import { ArrowIcon, Button, Text } from "@olivahealth/oli-ui";

import TopicBadge from "../../../components/common/TopicBadge";
import InnerCircleDateAndUserText from "../../../components/common/WellbeingProfileShape/InnerCircleDateAndUserText";
import useTranslation from "../../../hooks/useTranslation";
import WellbeingProfilePanel from "../../organisms/WellbeingProfilePanel";
import { WellbeingProfileCard_data$key as IFragment } from "./__generated__/WellbeingProfileCard_data.graphql";

interface IProps {
  data: IFragment;
  showRefreshButton?: boolean;
  redirectToNewWellbeingProfile?: boolean;
}

const WellbeingProfileCardFragment = graphql`
  fragment WellbeingProfileCard_data on WellbeingProfile {
    createdAt
    scores {
      score
      pillar
      needsFocus
    }
    topic
  }
`;

export default function WellbeingProfileCard({
  data,
  showRefreshButton = true,
  redirectToNewWellbeingProfile = false,
}: IProps) {
  const { createdAt, scores, topic } = useFragment<IFragment>(
    WellbeingProfileCardFragment,
    data,
  );
  const { t } = useTranslation("employeeDashboard");
  const { push } = useRouter();

  return (
    <WellbeingProfilePanel
      description={
        <>
          <Text color="secondary">
            {t("wellbeingProfileCard.workingOnTitle")}
          </Text>
          <TopicBadge topic={topic} />
        </>
      }
      footer={
        showRefreshButton ? (
          <>
            {t("wellbeingProfileCard.refreshYourProfile")}
            <span className="text-sm">
              <Button
                iconRight={<ArrowIcon direction="right" size={20} />}
                onClick={() =>
                  push(
                    redirectToNewWellbeingProfile ? "/wellbeing" : "/triaging",
                  )
                }
                variant="link"
              >
                {t("wellbeingProfileCard.refreshYourProfileLink")}
              </Button>
            </span>
          </>
        ) : undefined
      }
      innerCircleContent={
        <InnerCircleDateAndUserText
          completedAt={createdAt}
          dateFormat="LLL dd"
        />
      }
      noFocusAreasTitle={t("wellbeingProfileCard.noFocusAreasTitle")}
      noAreasOnTrackTitle={t("wellbeingProfileCard.noAreasOnTrackTitle")}
      scores={scores}
      title={t("yourWellbeingProfile")}
    />
  );
}
