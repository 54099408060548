import { getPillarForTopic } from "@olivahealth/graphql-server/src/domain/value-objects/MentalHealthPillars";
import MentalHealthTopics from "@olivahealth/graphql-server/src/domain/value-objects/MentalHealthTopics";
import { Badge } from "@olivahealth/oli-ui";
import { BadgeSize } from "@olivahealth/oli-ui/src/atoms/Badge";
import useTranslation from "../../../hooks/useTranslation";
import { getTriagingTopicsData } from "../../../rebrand/pages/Triaging/triagingData";

interface Props {
  topic: MentalHealthTopics;
  size?: BadgeSize;
}

export default function TopicBadge({ topic, size = "sm" }: Props) {
  const { t } = useTranslation("common");
  const variant =
    topic === MentalHealthTopics.OVERALL_WELLNESS
      ? "default"
      : getTriagingTopicsData[getPillarForTopic(topic)].colorRebrand;

  return (
    <Badge variant={variant} size={size}>
      {t(`mentalHealthTopics.${topic}`, {
        ns: "common",
      })}
    </Badge>
  );
}
