import { format } from "date-fns";
import { Text, TextColor } from "@olivahealth/oli-ui";

interface Props {
  completedAt: string | null;
  userName?: string;
  dateFormat?: string;
  textColor?: TextColor;
}

export default function InnerCircleDateAndUserText({
  completedAt,
  userName,
  dateFormat = "MMM dd",
  textColor = "primary",
}: Props) {
  const completedDate = completedAt
    ? format(new Date(completedAt), dateFormat)
    : null;

  return (
    <>
      {!completedDate && userName && (
        <Text size="xs" weight="bold" color={textColor}>
          {userName}
        </Text>
      )}
      {completedDate && (
        <Text size="xs" weight="bold" color={textColor}>
          {completedDate}
        </Text>
      )}
    </>
  );
}
