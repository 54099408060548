import { isPast } from "date-fns";
import AppointmentStatus from "@olivahealth/graphql-server/src/domain/value-objects/AppointmentStatus";

export function getDataProcessingConsent({
  employeeSettings,
  sessionSettings,
  sessionEndDate,
  sessionStatus,
}: {
  employeeSettings?: { allowDataProcessing?: boolean | null } | null;
  sessionSettings?: { allowDataProcessing?: boolean | null } | null;
  sessionEndDate: string;
  sessionStatus: AppointmentStatus;
}): boolean | null | undefined {
  if (
    isPast(new Date(sessionEndDate)) &&
    sessionStatus !== AppointmentStatus.ACTIVE
  ) {
    return Boolean(sessionSettings?.allowDataProcessing);
  }

  // Session setting has priority if is set
  if (typeof sessionSettings?.allowDataProcessing === "boolean") {
    return sessionSettings?.allowDataProcessing;
  }

  return employeeSettings?.allowDataProcessing;
}
