import { useRouter } from "next/router";
import { Button } from "@olivahealth/oli-ui";

import useTranslation from "../../../hooks/useTranslation";

import * as s from "./styles";

interface Props {
  isOngoing: boolean;
  id: string;
  isGroupSession: boolean;
  mobileView?: boolean;
  hasSummaryAvailableToView: boolean;
}

export default function SessionCardCTA({
  isOngoing,
  id,
  isGroupSession,
  mobileView = false,
  hasSummaryAvailableToView,
}: Props) {
  const { t } = useTranslation("professionalDashboard", {
    keyPrefix: "sessionCard",
  });
  const { push } = useRouter();

  if (hasSummaryAvailableToView) {
    return (
      <div className={s.ctaWrapper(mobileView)}>
        <Button
          width={mobileView ? "full" : "auto"}
          variant="secondary"
          onClick={() => {
            push(`/sessions/${id}`);
          }}
        >
          {t("seeSummary")}
        </Button>
      </div>
    );
  }

  if (!isOngoing) {
    return null;
  }

  return (
    <div className={s.ctaWrapper(mobileView)}>
      <Button
        width={mobileView ? "full" : "auto"}
        variant="base"
        onClick={() => {
          push(`/sessions/${id}/talk`);
        }}
      >
        {t(`${isGroupSession ? "joinClass" : "joinSession"}`)}
      </Button>
    </div>
  );
}
