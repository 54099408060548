import ctl from "@netlify/classnames-template-literals";

export const noSessionsWrapper = ctl(`
    mx-auto
    flex
    h-96
    max-w-lg
    flex-col
    items-center
    justify-center
`);

export const sessionsCardWrapper = ctl(`
    mt-9
    grid
    gap-4
`);

export const noSessionsTodayWrapper = ctl(`
    flex
    items-center
    mt-9
`);

export const noSessionsTodayTextWrapper = ctl(`
    ml-6
    flex
    flex-col
    justify-center
    lg:ml-8
`);
