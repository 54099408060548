import cx from "classnames";
import { graphql, useFragment } from "react-relay";
import { useState } from "react";
import { Text } from "@olivahealth/oli-ui";
import { useMediaQuery } from "react-responsive";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Mousewheel, Navigation } from "swiper/modules";
import Image from "../../../components/common/Image";
import useTranslation from "../../../hooks/useTranslation";
import SessionCard from "../../organisms/SessionCard";
import { UpcomingEventsFragment_data$key as IFragment } from "./__generated__/UpcomingEventsFragment_data.graphql";
import { upcomingEvents as s } from "./styles";
import "swiper/css";

interface Props {
  addSpaceAfter: boolean;
  data: IFragment;
}

const UpcomingEventsFragment = graphql`
  fragment UpcomingEventsFragment_data on Query
  @argumentDefinitions(userId: { type: "String" }) {
    sessions(
      filterBy: { userId: $userId }
      only: UPCOMING
      include: { groupSessions: true, cancelled: false }
      sortBy: { scheduledDate: ASC }
      first: 2
    ) {
      nodes {
        __typename
        ... on GroupSession {
          id
        }
        ... on Session {
          id
        }
        ...useSessionCard_session
      }
    }
  }
`;

export default function UpcomingEvents({
  addSpaceAfter,
  data,
}: Props): JSX.Element {
  const { t } = useTranslation("employeeDashboard", {
    keyPrefix: "upcomingEvents",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

  const fragmentData = useFragment<IFragment>(UpcomingEventsFragment, data);
  const sessions =
    fragmentData?.sessions.nodes.filter(
      (session) => Object.keys(session).length !== 0,
    ) ?? [];

  return (
    <div
      className={cx("flex flex-col", { "mb-14": addSpaceAfter })}
      data-testid="upcoming-events"
    >
      <Text variant="h3" gutter="xl">
        {t("title")}
      </Text>

      {!sessions.length && <EmptyState />}
      {sessions.length > 0 && isTabletOrMobile && (
        <div>
          <Swiper
            grabCursor
            modules={[A11y, Mousewheel, Navigation]}
            mousewheel={{
              releaseOnEdges: true,
            }}
            spaceBetween={16}
            slidesPerView={sessions.length === 1 ? 1 : 1.5}
            threshold={20}
            className="!p-1"
          >
            {sessions.map((session) => (
              <SwiperSlide key={session.id}>
                <SessionCard session={session} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
      {sessions.length > 0 && !isTabletOrMobile && (
        <div className="grid grid-cols-2 gap-6">
          {sessions.map((session) => (
            <SessionCard key={session.id} session={session} />
          ))}
        </div>
      )}
    </div>
  );
}

function EmptyState() {
  const { t, Trans } = useTranslation("employeeDashboard", {
    keyPrefix: "upcomingEvents",
  });
  const [isHovered, setIsHovered] = useState<boolean>(false);

  return (
    <div
      className={s.emptyStateWrapper}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Image
        src={
          !isHovered
            ? "/img/placeholder_calendar_default.png"
            : "/img/placeholder_calendar_hover.png"
        }
        alt="empty state icon"
        className="mb-5 md:mb-0"
        width={72}
        height={72}
      />

      <div className="flex flex-col md:ml-5">
        <Text color="secondary" gutter="sm" weight="bold">
          {t("emptyStateTitle")}
        </Text>
        <Text color="secondary">
          <Trans
            i18nKey={t("emptyStateSubtitle")}
            components={[
              <a
                href="/sessions"
                className="text-purple-500"
                key="variantLink"
              />,
            ]}
          />
        </Text>
      </div>
    </div>
  );
}
