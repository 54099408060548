import Link from "next/link";
import cx from "classnames";
import { Card, Text } from "@olivahealth/oli-ui";

import useTranslation from "../../../hooks/useTranslation";

import { GroupSessionDetails, SessionDetails } from "./SessionDetails";
import SessionCardImage from "./SessionCardImage";
import SessionCardCTA from "./SessionCardCTA";
import SessionCardEmployeeDetails from "./SessionCardEmployeeDetails";
import SessionCardTags from "./SessionCardTags";
import useProfessionalSessionCard, {
  EmptyData,
  UseProfessionalSessionCardData,
} from "./useProfessionalSessionCard";
import AutomatedSummaryStatus from "./AutomatedSummaryStatus";
import { useProfessionalSessionCard_fragment$key as IProfessionalSessionCardKeyFragment } from "./__generated__/useProfessionalSessionCard_fragment.graphql";

import * as s from "./styles";

interface Props {
  session: IProfessionalSessionCardKeyFragment | null;
}

export default function SessionCard({ session }: Props) {
  const { t } = useTranslation("professionalDashboard");
  const { data } = useProfessionalSessionCard({ session });

  const emptyData = data as EmptyData | undefined;
  if (!emptyData?.hasData) {
    return null;
  }

  const {
    sessionId,
    linkHref,
    isCancelled,
    isPast,
    isCheckInSession,
    isGroupSession,
    illustrationSrc,
    title,
    isAwaitingPayment,
    cancelledById,
    cancelledByName,
    startDate,
    endDate,
    rescheduledPreviousStartDate,
    participantsCount,
    isOngoing,
    employee,
    employeeConsent,
    isToday,
    hasSummaryAvailableToView,
    sessionLanguageSupportsAutomatedSummary,
    transcriptionHasError,
  } = data as UseProfessionalSessionCardData;

  return (
    <div className={s.cardWrapper}>
      <Link href={linkHref} data-testid={`card-session-${sessionId}`} passHref>
        <Card border={false}>
          <div
            className={cx(s.cardContent, {
              "bg-neutral-50": !isToday || isPast,
              "bg-purple-50": isToday && !isPast,
            })}
          >
            <div className="flex w-full">
              <div className={s.imageWrapper}>
                <SessionCardImage
                  isCancelled={isCancelled}
                  isPast={isPast}
                  isCheckIn={isCheckInSession}
                  isGroupSession={isGroupSession}
                  groupSessionIllustrationSrc={illustrationSrc}
                  isAwaitingPayment={isAwaitingPayment}
                  cancelledById={cancelledById}
                  cancelledByName={cancelledByName}
                />
              </div>
              <div className={s.detailsWrapper(isCancelled || isPast)}>
                <div className="flex justify-between">
                  {isGroupSession ? (
                    <GroupSessionDetails
                      workshopTitle={title}
                      isCancelled={isCancelled}
                      startDate={startDate}
                      endDate={endDate}
                      participantsCount={participantsCount}
                    />
                  ) : (
                    <SessionDetails
                      isCancelled={isCancelled}
                      startDate={startDate}
                      endDate={endDate}
                      rescheduledPreviousStartDate={
                        rescheduledPreviousStartDate
                      }
                      isOngoing={isOngoing}
                    />
                  )}
                  <div className="flex flex-col items-end">
                    <SessionCardCTA
                      id={sessionId}
                      isOngoing={isOngoing}
                      isGroupSession={isGroupSession}
                      hasSummaryAvailableToView={hasSummaryAvailableToView}
                    />
                    <SessionCardTags
                      isAwaitingPayment={isAwaitingPayment}
                      isCancelled={isCancelled}
                      cancelledById={cancelledById}
                      cancelledByName={cancelledByName}
                    />
                  </div>
                </div>
                {!isGroupSession && (
                  <div className={s.bottomRowWrapper}>
                    <div>
                      <SessionCardEmployeeDetails employee={employee} />
                      <AutomatedSummaryStatus
                        employeeConsent={employeeConsent}
                        sessionLanguageSupportsAutomatedSummary={
                          sessionLanguageSupportsAutomatedSummary
                        }
                        transcriptionHasError={transcriptionHasError}
                      />
                    </div>
                    {isCheckInSession && (
                      <div className={s.checkInLabelWrapper}>
                        <Text>{t("sessionCard.checkInSession")}</Text>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <SessionCardCTA
              id={sessionId}
              isOngoing={isOngoing}
              isGroupSession={isGroupSession}
              mobileView
              hasSummaryAvailableToView={hasSummaryAvailableToView}
            />
          </div>
        </Card>
      </Link>
    </div>
  );
}
