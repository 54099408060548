import ctl from "@netlify/classnames-template-literals";

export const getStartedBanner = {
  content: ctl(`
    flex
    flex-col
    w-full
    gap-8
    items-center
    justify-between
    lg:flex-row
  `),
  textWrapper: ctl(`
    flex
    flex-col
    w-full
    order-last
    lg:order-first
  `),
};

export const upcomingEvents = {
  emptyStateWrapper: ctl(`
    flex
    flex-col
    items-start
    md:flex-row
    md:items-center
  `),
};

export const outlineCard = {
  wrapper: ctl(`
    flex
    flex-col
    gap-y-6
    gap-x-10
    items-center
    animate-fade-in-up
    rounded-xl
    bg-neutral-50
    p-4
    md:flex-row
    lg:px-10
    lg:py-8
  `),
};

export const selfGuidedWrapper = ctl(`
  flex
  w-full
  flex-col
  gap-2
  md:grid
  md:grid-cols-4
`);

export const bookingConfirmationDialog = {
  contentWrapper: ctl(`
    flex
    w-96
    flex-col
    gap-y-12
    pt-12
    text-left
  `),
  eventIconWrapper: ctl(`
    relative
    mr-4
    h-14
    w-14
  `),
  infoIcon: ctl(`
    mr-2
    h-4
    w-4
  `),
};
