import { Tag } from "@olivahealth/oli-ui";

import useTranslation from "../../../hooks/useTranslation";
import { useUserData } from "../../../services/contexts/UserDataContext";

import * as s from "./styles";

interface Props {
  isAwaitingPayment: boolean;
  isCancelled: boolean;
  cancelledById?: string | null;
  cancelledByName?: string | null;
  mobileView?: boolean;
}

interface SessionTagProps {
  variant: "warning" | "negative";
  label: string;
  mobileView: boolean;
}

const SessionTag = ({ variant, label, mobileView }: SessionTagProps) => (
  <div className={s.tagWrapper(mobileView)}>
    <Tag
      variant={variant}
      fillWidth={mobileView}
      align={mobileView ? "center" : "left"}
    >
      {mobileView ? label : <div className={s.tagLabel}>{label}</div>}
    </Tag>
  </div>
);

export default function SessionCardTags({
  isAwaitingPayment,
  isCancelled,
  cancelledById,
  cancelledByName,
  mobileView = false,
}: Props) {
  const { t } = useTranslation("professionalDashboard", {
    keyPrefix: "sessionCard",
  });

  const { data: user } = useUserData();

  const wasCancelledBySelf = user?.id === cancelledById;
  const name = wasCancelledBySelf
    ? t("cancelledByYou")
    : t("cancelled", { name: cancelledByName });

  return (
    <>
      {isAwaitingPayment && (
        <SessionTag
          variant="warning"
          mobileView={mobileView}
          label={t("paymentPending")}
        />
      )}
      {isCancelled && (
        <SessionTag variant="negative" mobileView={mobileView} label={name} />
      )}
    </>
  );
}
