import cx from "classnames";
import {
  CheckIcon,
  Text,
  ExclamationIcon,
  CrossCircleIcon,
} from "@olivahealth/oli-ui";

import useTranslation from "../../../hooks/useTranslation";

interface Props {
  employeeConsent: boolean | null;
  sessionLanguageSupportsAutomatedSummary: boolean;
  transcriptionHasError: boolean;
}

const getEmployeeSpecificInformation = (
  employeeConsent: boolean | null,
  sessionLanguageSupportsAutomatedSummary: boolean,
  transcriptionHasError: boolean,
) => {
  if (!sessionLanguageSupportsAutomatedSummary) {
    return {
      icon: <CrossCircleIcon size={16} />,
      statusTranslationKey: "languageNotSupported",
      textColor: "text-neutral-600",
    };
  }

  if (transcriptionHasError) {
    return {
      icon: <CrossCircleIcon size={16} />,
      statusTranslationKey: "transcriptionError",
      textColor: "text-neutral-600",
    };
  }

  switch (employeeConsent) {
    case null:
      return {
        icon: <ExclamationIcon size={16} variant="triangle" />,
        statusTranslationKey: "noResponse",
        textColor: "text-neutral-600",
      };
    case true:
      return {
        icon: <CheckIcon size={16} />,
        statusTranslationKey: "allowed",
        textColor: "text-status-green-500",
      };
    case false:
      return {
        icon: <CrossCircleIcon size={16} />,
        statusTranslationKey: "notAllowed",
        textColor: "text-neutral-600",
      };
  }
};

export default function AutomatedSummaryStatus({
  employeeConsent,
  sessionLanguageSupportsAutomatedSummary,
  transcriptionHasError,
}: Props) {
  const { t } = useTranslation("professionalDashboard", {
    keyPrefix: "sessionCard.summarisationStatus",
  });

  const employeeSpecificInformation = getEmployeeSpecificInformation(
    employeeConsent,
    sessionLanguageSupportsAutomatedSummary,
    transcriptionHasError,
  );

  return (
    <div
      className={cx("mt-2 flex items-center text-neutral-600", {
        [employeeSpecificInformation.textColor]: true,
      })}
    >
      <div className="mr-1">
        <div className="mr-1">{employeeSpecificInformation.icon}</div>
      </div>
      <Text size="sm" weight="medium">
        {t(employeeSpecificInformation.statusTranslationKey)}
      </Text>
    </div>
  );
}
