/**
 * @generated SignedSource<<7f49fe2684f39aeb9772822f9979d0a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpcomingEventsFragment_data$data = {
  readonly sessions: {
    readonly nodes: ReadonlyArray<{
      readonly __typename: string;
      readonly id?: string;
      readonly " $fragmentSpreads": FragmentRefs<"useSessionCard_session">;
    }>;
  };
  readonly " $fragmentType": "UpcomingEventsFragment_data";
};
export type UpcomingEventsFragment_data$key = {
  readonly " $data"?: UpcomingEventsFragment_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpcomingEventsFragment_data">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "userId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpcomingEventsFragment_data",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "userId",
              "variableName": "userId"
            }
          ],
          "kind": "ObjectValue",
          "name": "filterBy"
        },
        {
          "kind": "Literal",
          "name": "first",
          "value": 2
        },
        {
          "kind": "Literal",
          "name": "include",
          "value": {
            "cancelled": false,
            "groupSessions": true
          }
        },
        {
          "kind": "Literal",
          "name": "only",
          "value": "UPCOMING"
        },
        {
          "kind": "Literal",
          "name": "sortBy",
          "value": {
            "scheduledDate": "ASC"
          }
        }
      ],
      "concreteType": "SessionsResponseConnection",
      "kind": "LinkedField",
      "name": "sessions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v0/*: any*/),
              "type": "GroupSession",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v0/*: any*/),
              "type": "Session",
              "abstractKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "useSessionCard_session"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "a2dff27459828aa30cedde70e3d5c933";

export default node;
