import { useRouter } from "next/router";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { Suspense } from "react";

import AppLayout from "../components/common/AppLayout";
import { CenteredInfinityLoader } from "../rebrand/molecules/Loaders/InfinityLoader";
import AuthMiddleware from "../rebrand/organisms/auth/AuthMiddleware";
import EmployeeDashboard from "../rebrand/pages/EmployeeDashboard";
import ProfessionalDashboard from "../rebrand/pages/ProfessionalDashboard";
import { useUserData } from "../services/contexts/UserDataContext";
import { PAGE_ROUTE } from "../utils/routing/routes";

function IndexComponent() {
  const { push } = useRouter();
  const { data: user } = useUserData();

  if (user?.organisation?.hasTeamSessionsOnly) {
    push(PAGE_ROUTE.ORGANISATION.TEAM_SESSIONS.HOME);
    return;
  }

  if (user?.role?.includes("MHP")) {
    return <ProfessionalDashboard />;
  }

  return <EmployeeDashboard />;
}

export default function IndexPage(): JSX.Element {
  return (
    <AuthMiddleware roles={[]}>
      <AppLayout>
        <Suspense fallback={<CenteredInfinityLoader height="full" />}>
          <IndexComponent />
        </Suspense>
      </AppLayout>
    </AuthMiddleware>
  );
}

export async function getServerSideProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale)),
    },
  };
}
